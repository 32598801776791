import { useEffect } from "react"
import Layout from "../layout"
import { Link } from "react-router-dom"

function ContactUs() {
    useEffect(() => {
        window.scrollTo(0, 0)
        let body = document.body;
        body.classList.remove("mobile-nav-active")
    }, [])
    return (
        <Layout activePage='contact'>
            <main className="page">
                <div className="content-title">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <h2>Contact Us</h2>
                            </div>
                        </div>
                    </div>
                </div>
                <section id="about">
                    <div className='container'>
                        <div className='row'>
                            <div className='col-12 col-md-6'>





                                <p className="d-grid gap-2">
                                    <strong>Location</strong>
                                </p>

                                <p>Address: Mandir Sati Mata Sheela Devi, Thathari Gate, Batala, Punjab 143505</p>



                                <div className="mb-3">
                                    <iframe style={{ border: 0, width: "100%", height: "350px" }} src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13562.702103314505!2d75.2013!3d31.8065919!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x391bc25600160735%3A0xf75a182b770fd7a8!2sSati%20Mata%20Sheela%20Devi!5e0!3m2!1sen!2sin!4v1700536797745!5m2!1sen!2sin" ></iframe>
                                </div>



                            </div>
                            <div className='col-12 col-md-6 px-5'>


                                <div className="row">
                                    <div className="col">
                                        <p>For any kind of query please contact below numbers:</p>
                                    </div>
                                </div>

                                <div className="row">
                                   
                                    <div className="col">
                                        <p>
                                            <strong>President (प्रधान)</strong><br />
                                            <span>Sh. Sushil Mahajan</span><br />
                                            <span>M: 98151-19129</span>

                                        </p>
                                    </div>
                                    <div className="col">
                                        <p>
                                            <strong>General Secretary (महासचिव)</strong><br />
                                            <span>Sh. Sahil Mahajan</span><br />
                                            <span>M: 75720-00006</span>

                                        </p>
                                    </div>
                                </div>



                                <div className="row">
                                    
                                    <div className="col">
                                        <p>
                                            <strong>Finance Secretary (वित्त सचिव)</strong><br />
                                            <span>Sh. Rohit Mahajan</span><br />
                                            <span>M: 98155-37222</span>

                                        </p>
                                    </div>
                                     <div className="col">
                                        <p>
                                            <strong>Chairman (चेयरमैन)</strong><br />
                                            <span>Sh. Ashok Mahajan</span><br />
                                            <span>M: 98726-54176</span>

                                        </p>
                                    </div>
                                </div>
                                
                                <div className="row">
                                    
                                    <div className="col">
                                        <p>
                                            <strong>Prohit (प्रोहित)</strong><br />
                                            <span>Pawan Kumar (Gora)</span><br />
                                            <span>M: 98724-07878</span>

                                        </p>
                                    </div>
                                     <div className="col">
                                        <p>
                                            <strong>Prohit (प्रोहित)</strong><br />
                                            <span>Vishal Dogra</span><br />
                                            <span>M: 98142-44072</span>

                                        </p>
                                    </div>
                                </div>
                                
                                <div className="row">
                                    
                                    <div className="col">
                                        <p>
                                            <strong>Manager</strong><br />
                                            <span>Sh. Vikram Mahajan</span><br />
                                            <span>M: 97790-48213</span>

                                        </p>
                                    </div>
                                     <div className="col">
                                        
                                    </div>
                                </div>
                                <div className="mt-3">
                                    <strong>Email Us</strong>
                                    <p>akhilbeotramahajansabha@gmail.com</p>
                                </div>

                                <div className="mt-3">
                                    <a href="https://www.google.com/maps/dir//Sati+Mata+Sheela+Devi+Mandir+Sati+Mata+Sheela+Devi+Thathari+Gate+Batala,+Punjab+143505/@31.8065919,75.2013,14z/data=!4m5!4m4!1m0!1m2!1m1!1s0x391bc25600160735:0xf75a182b770fd7a8" target="_blank" className="btn btn-danger">Google Map Directions</a>
                                </div>


                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </Layout >
    )
}
export default ContactUs