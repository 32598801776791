import { useEffect } from "react"
import Carousel from "../../components/carousel"
import Layout from "../layout"
import { Link } from "react-router-dom"

export default function Home() {
    useEffect(() => {
        window.scrollTo(0, 0)
        let body = document.body;
        body.classList.remove("mobile-nav-active")
    }, [])

    return (
        <Layout activePage='home'>
            <Carousel items={[{
                item: <video playsInline autoPlay muted loop id="bgvid" style={{ width: "100%" }}>
                    <source src={process.env.PUBLIC_URL + "/abms-main-banner.webm"} type="video/webm" />
                    <source src={process.env.PUBLIC_URL + "/abms-main-banner.mp4"} type="video/mp4" />
                </video>,
                interval: 17000
            }
                // , {
                //     item:
                //         <img
                //             // style={{ height: "624px" }}
                //             className="d-block w-100"
                //             src={require('../../assets/img/banner-2.jpg')}
                //             alt="slider image"
                //         />,
                //     interval: 5000
                // },
                //  {
                //     item:
                //         <img
                //             // style={{ height: "624px" }}
                //             className="d-block w-100"
                //             src={require('../../assets/img/banner-3.jpg')}
                //             alt="slider image"
                //         />,
                //     interval: 5000
                // },
                // {
                //     item:
                //         <img
                //             // style={{ height: "624px" }}
                //             className="d-block w-100"
                //             src={require('../../assets/img/banner-4.jpg')}
                //             alt="slider image"
                //         />,
                //     interval: 5000
                // }
            ]} />

            <main id="main">

                <section id="about" className="about">
                    <div className="container" data-aos="fade-up">

                        <div className="section-header">

                            <p>About <span>Us</span></p>
                        </div>

                        <div className="row gy-4">
                            <div className="col-lg-7 position-relative about-img" data-aos="fade-up" data-aos-delay="150">
                                {/* <div className="call-us position-absolute">
                                    <h4>Call us now</h4>
                                    <p>+91 7889210104</p>
                                </div> */}
                            </div>
                            <div className="col-lg-5 d-flex align-items-end" data-aos="fade-up" data-aos-delay="300">
                                <div className="content ps-0 ps-lg-5">
                                    <p className="fst-italic">
                                        Welcome to the heart of tradition, the "Akhil Beotra Mahajan Sabha."Our community's tapestry of history spans an impressive 400-500 years, reaching back to the ancient "kingra" village in Lahore, now nestled within the borders of Pakistan. The resonance of our heritage echoes through the endeavors of four extraordinary individuals: Late Sh. Tirath Ram Mahajan, Sh. Karam Chand Mahajan, Sh. Mehanga Ram Mahajan, and Sh. Shiv Deyal Mahajan.
                                    </p>
                                    {/* <ul>
                                        <li><i className="bi bi-check2-all"></i> PEDA (Punjab Energy Development Agency)</li>
                                        <li><i className="bi bi-check2-all"></i> MNRE (Ministry of New & Renewable Energy) Govt. of INDIA.</li>
                                        <li><i className="bi bi-check2-all"></i> PSPCL (Punjab State Power Corporation Limited)</li>
                                    </ul> */}
                                    <p>
                                        In 1949, these stalwarts embarked on a profound journey to Pakistan, where amidst the echoes of time, they retrieved the sacred "Jal" and "Mohre." Upon their return to Batala, Punjab, their unwavering determination became the cornerstone for a new temple—a sacred space embodying the essence of our cultural identity

                                        <Link to="/about-us"><strong>...read more</strong></Link>
                                    </p>

                                    <div className="position-relative mt-4">
                                        <img src={require('../../assets/img/about-2.jpg')} className="img-fluid" alt="" />
                                        <a href="#" className="glightbox play-btn"></a>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>

                <section id="why-us" className="why-us section-bg">
                    <div className="container" data-aos="fade-up">

                        <div className="section-header">

                            <p>Upcoming <span>Events</span></p>
                        </div>

                        <div className="row gy-4">

                            <div className="col-lg-4" data-aos="fade-up" data-aos-delay="100">
                                <div className="why-box">
                                    <h3>Varshik Mail Program</h3>
                                    <p>
                                        Each year on Kartik Punya day, the Beotra community gathers in Batala for the Varshik Mail Program. This event celebrates our shared history and strengthens our lasting bonds, bringing generations together in a spirit of unity and joy.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-8 d-flex align-items-center">
                                <div className="row gy-4">

                                    <div className="col-xl-4" data-aos="fade-up" data-aos-delay="200">
                                        <div className="icon-box d-flex flex-column justify-content-center align-items-center">
                                            <i className="bi bi-clipboard-data"></i>
                                            <h4>Coming soon...</h4>
                                            <p>Consequuntur sunt aut quasi enim aliquam quae harum pariatur laboris nisi ut aliquip</p>
                                        </div>
                                    </div>
                                    <div className="col-xl-4" data-aos="fade-up" data-aos-delay="300">
                                        <div className="icon-box d-flex flex-column justify-content-center align-items-center">
                                            <i className="bi bi-gem"></i>
                                            <h4>Coming soon...</h4>
                                            <p>Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt</p>
                                        </div>
                                    </div>

                                    <div className="col-xl-4" data-aos="fade-up" data-aos-delay="400">
                                        <div className="icon-box d-flex flex-column justify-content-center align-items-center">
                                            <i className="bi bi-inboxes"></i>
                                            <h4>Coming soon...</h4>
                                            <p>Aut suscipit aut cum nemo deleniti aut omnis. Doloribus ut maiores omnis facere</p>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>

                    </div>
                </section>


                {/* <section id="testimonials" className="testimonials">
                    <div className="container" data-aos="fade-up">

                        <div className="section-header">
                            <p>What Are They <span>Saying About Us</span></p>
                        </div>

                        <Carousel items={[{
                            item: <div className="testimonial-item">
                                <div className="row gy-4 justify-content-center">
                                    <div className="col-lg-6">
                                        <div className="testimonial-content">
                                            <p>
                                                <i className="bi bi-quote quote-icon-left"></i>
                                                Proin iaculis purus consequat sem cure digni ssim donec porttitora entum suscipit rhoncus. Accusantium quam, ultricies eget id, aliquam eget nibh et. Maecen aliquam, risus at semper.
                                                <i className="bi bi-quote quote-icon-right"></i>
                                            </p>
                                            <h3>Saul Goodman</h3>
                                            <h4>Ceo &amp; Founder</h4>
                                            <div className="stars">
                                                <i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-2 text-center">
                                        <img src="assets/img/testimonials/testimonials-1.jpg" className="img-fluid testimonial-img" alt="" />
                                    </div>
                                </div>
                            </div>
                        }, {
                            item:
                                <div className="testimonial-item">
                                    <div className="row gy-4 justify-content-center">
                                        <div className="col-lg-6">
                                            <div className="testimonial-content">
                                                <p>
                                                    <i className="bi bi-quote quote-icon-left"></i>
                                                    Export tempor illum tamen malis malis eram quae irure esse labore quem cillum quid cillum eram malis quorum velit fore eram velit sunt aliqua noster fugiat irure amet legam anim culpa.
                                                    <i className="bi bi-quote quote-icon-right"></i>
                                                </p>
                                                <h3>Sara Wilsson</h3>
                                                <h4>Designer</h4>
                                                <div className="stars">
                                                    <i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-2 text-center">
                                            <img src="assets/img/testimonials/testimonials-2.jpg" className="img-fluid testimonial-img" alt="" />
                                        </div>
                                    </div>
                                </div>
                        }
                        ]} />


                    </div>
                </section>

                <section id="events" className="events section-bg">
                    <div className="container-fluid" data-aos="fade-up">

                        <div className="section-header">
                            <h2>Events</h2>
                            <p>Share <span>Your Moments</span> In Our Restaurant</p>
                        </div>

                        <div className="slides-3 swiper" data-aos="fade-up" data-aos-delay="100">


                            <Carousel items={[{
                                item: <div className="row">
                                    <div className="swiper-slide col-12 col-md-4 event-item d-flex flex-column justify-content-end event-1">
                                        <h3>Custom Parties</h3>
                                        <div className="price align-self-start">$99</div>
                                        <p className="description">
                                            Quo corporis voluptas ea ad. Consectetur inventore sapiente ipsum voluptas eos omnis facere. Enim facilis veritatis id est rem repudiandae nulla expedita quas.
                                        </p>
                                    </div>

                                    <div className="swiper-slide  col-12 col-md-4 event-item d-flex flex-column justify-content-end event-2">
                                        <h3>Private Parties</h3>
                                        <div className="price align-self-start">$289</div>
                                        <p className="description">
                                            In delectus sint qui et enim. Et ab repudiandae inventore quaerat doloribus. Facere nemo vero est ut dolores ea assumenda et. Delectus saepe accusamus aspernatur.
                                        </p>
                                    </div>

                                    <div className="swiper-slide  col-12 col-md-4 event-item d-flex flex-column justify-content-end event-3">
                                        <h3>Birthday Parties</h3>
                                        <div className="price align-self-start">$499</div>
                                        <p className="description">
                                            Laborum aperiam atque omnis minus omnis est qui assumenda quos. Quis id sit quibusdam. Esse quisquam ducimus officia ipsum ut quibusdam maxime. Non enim perspiciatis.
                                        </p>
                                    </div></div>
                            }
                            ]} />

                        </div>

                    </div>
                </section>


                <section id="chefs" className="chefs section-bg">
                    <div className="container" data-aos="fade-up">

                        <div className="section-header">
                            <h2>Chefs</h2>
                            <p>Our <span>Proffesional</span> Chefs</p>
                        </div>

                        <div className="row gy-4">

                            <div className="col-lg-4 col-md-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="100">
                                <div className="chef-member">
                                    <div className="member-img">
                                        <img src="assets/img/chefs/chefs-1.jpg" className="img-fluid" alt="" />
                                        <div className="social">
                                            <a href=""><i className="bi bi-twitter"></i></a>
                                            <a href=""><i className="bi bi-facebook"></i></a>
                                            <a href=""><i className="bi bi-instagram"></i></a>
                                            <a href=""><i className="bi bi-linkedin"></i></a>
                                        </div>
                                    </div>
                                    <div className="member-info">
                                        <h4>Walter White</h4>
                                        <span>Master Chef</span>
                                        <p>Velit aut quia fugit et et. Dolorum ea voluptate vel tempore tenetur ipsa quae aut. Ipsum exercitationem iure minima enim corporis et voluptate.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="200">
                                <div className="chef-member">
                                    <div className="member-img">
                                        <img src="assets/img/chefs/chefs-2.jpg" className="img-fluid" alt="" />
                                        <div className="social">
                                            <a href=""><i className="bi bi-twitter"></i></a>
                                            <a href=""><i className="bi bi-facebook"></i></a>
                                            <a href=""><i className="bi bi-instagram"></i></a>
                                            <a href=""><i className="bi bi-linkedin"></i></a>
                                        </div>
                                    </div>
                                    <div className="member-info">
                                        <h4>Sarah Jhonson</h4>
                                        <span>Patissier</span>
                                        <p>Quo esse repellendus quia id. Est eum et accusantium pariatur fugit nihil minima suscipit corporis. Voluptate sed quas reiciendis animi neque sapiente.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="300">
                                <div className="chef-member">
                                    <div className="member-img">
                                        <img src="assets/img/chefs/chefs-3.jpg" className="img-fluid" alt="" />
                                        <div className="social">
                                            <a href=""><i className="bi bi-twitter"></i></a>
                                            <a href=""><i className="bi bi-facebook"></i></a>
                                            <a href=""><i className="bi bi-instagram"></i></a>
                                            <a href=""><i className="bi bi-linkedin"></i></a>
                                        </div>
                                    </div>
                                    <div className="member-info">
                                        <h4>William Anderson</h4>
                                        <span>Cook</span>
                                        <p>Vero omnis enim consequatur. Voluptas consectetur unde qui molestiae deserunt. Voluptates enim aut architecto porro aspernatur molestiae modi.</p>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </section>


                <section id="book-a-table" className="book-a-table">
                    <div className="container" data-aos="fade-up">

                        <div className="section-header">

                            <p>Get <span>Quotation</span> With Us</p>
                        </div>

                        <div className="row g-0">

                            <div className="col-lg-4 reservation-img" data-aos="zoom-out" data-aos-delay="200"></div>

                            <div className="col-lg-8 d-flex align-items-center reservation-form-bg">
                                <form action="forms/book-a-table.php" method="post" role="form" className="php-email-form" data-aos="fade-up" data-aos-delay="100">
                                    <div className="row gy-4">
                                        <div className="col-lg-4 col-md-6">
                                            <input type="text" name="name" className="form-control" id="name" placeholder="Your Name" data-rule="minlen:4" data-msg="Please enter at least 4 chars" />
                                            <div className="validate"></div>
                                        </div>
                                        <div className="col-lg-4 col-md-6">
                                            <input type="email" className="form-control" name="email" id="email" placeholder="Your Email" data-rule="email" data-msg="Please enter a valid email" />
                                            <div className="validate"></div>
                                        </div>
                                        <div className="col-lg-4 col-md-6">
                                            <input type="text" className="form-control" name="phone" id="phone" placeholder="Your Phone" data-rule="minlen:4" data-msg="Please enter at least 4 chars" />
                                            <div className="validate"></div>
                                        </div>
                                        <div className="col-lg-4 col-md-6">
                                            <input type="text" name="date" className="form-control" id="date" placeholder="Date" data-rule="minlen:4" data-msg="Please enter at least 4 chars" />
                                            <div className="validate"></div>
                                        </div>
                                        <div className="col-lg-4 col-md-6">
                                            <input type="text" className="form-control" name="time" id="time" placeholder="Time" data-rule="minlen:4" data-msg="Please enter at least 4 chars" />
                                            <div className="validate"></div>
                                        </div>
                                        <div className="col-lg-4 col-md-6">
                                            <input type="number" className="form-control" name="people" id="people" placeholder="# of people" data-rule="minlen:1" data-msg="Please enter at least 1 chars" />
                                            <div className="validate"></div>
                                        </div>
                                    </div>
                                    <div className="form-group mt-3">
                                        <textarea className="form-control" name="message" placeholder="Message"></textarea>
                                        <div className="validate"></div>
                                    </div>
                                    <div className="mb-3">
                                        <div className="loading">Loading</div>
                                        <div className="error-message"></div>
                                        <div className="sent-message">Your booking request was sent. We will call back or send an Email to confirm your reservation. Thank you!</div>
                                    </div>
                                    <div className="text-center"><button type="submit">Book a Table</button></div>
                                </form>
                            </div>

                        </div>

                    </div>
                </section>

                <section id="gallery" className="gallery section-bg">
                    <div className="container" data-aos="fade-up">

                        <div className="section-header">
                            <h2>gallery</h2>
                            <p>Check <span>Our Gallery</span></p>
                        </div>

                        <div className="gallery-slider swiper">
                            <div className="swiper-wrapper align-items-center">
                                <div className="swiper-slide"><a className="glightbox" data-gallery="images-gallery" href="assets/img/gallery/gallery-1.jpg"><img src="assets/img/gallery/gallery-1.jpg" className="img-fluid" alt="" /></a></div>
                                <div className="swiper-slide"><a className="glightbox" data-gallery="images-gallery" href="assets/img/gallery/gallery-2.jpg"><img src="assets/img/gallery/gallery-2.jpg" className="img-fluid" alt="" /></a></div>
                                <div className="swiper-slide"><a className="glightbox" data-gallery="images-gallery" href="assets/img/gallery/gallery-3.jpg"><img src="assets/img/gallery/gallery-3.jpg" className="img-fluid" alt="" /></a></div>
                                <div className="swiper-slide"><a className="glightbox" data-gallery="images-gallery" href="assets/img/gallery/gallery-4.jpg"><img src="assets/img/gallery/gallery-4.jpg" className="img-fluid" alt="" /></a></div>
                                <div className="swiper-slide"><a className="glightbox" data-gallery="images-gallery" href="assets/img/gallery/gallery-5.jpg"><img src="assets/img/gallery/gallery-5.jpg" className="img-fluid" alt="" /></a></div>
                                <div className="swiper-slide"><a className="glightbox" data-gallery="images-gallery" href="assets/img/gallery/gallery-6.jpg"><img src="assets/img/gallery/gallery-6.jpg" className="img-fluid" alt="" /></a></div>
                                <div className="swiper-slide"><a className="glightbox" data-gallery="images-gallery" href="assets/img/gallery/gallery-7.jpg"><img src="assets/img/gallery/gallery-7.jpg" className="img-fluid" alt="" /></a></div>
                                <div className="swiper-slide"><a className="glightbox" data-gallery="images-gallery" href="assets/img/gallery/gallery-8.jpg"><img src="assets/img/gallery/gallery-8.jpg" className="img-fluid" alt="" /></a></div>
                            </div>
                            <div className="swiper-pagination"></div>
                        </div>

                    </div>
                </section> */}

                <section id="contact" className="contact">
                    <div className="container" data-aos="fade-up">

                        <div className="section-header">

                            <p>Any Query? <span>Contact Us</span></p>
                        </div>

                        <div className="mb-3">
                            <iframe style={{ border: 0, width: "100%", height: "350px" }} src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13562.702103314505!2d75.2013!3d31.8065919!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x391bc25600160735%3A0xf75a182b770fd7a8!2sSati%20Mata%20Sheela%20Devi!5e0!3m2!1sen!2sin!4v1700536797745!5m2!1sen!2sin" ></iframe>
                        </div>

                        <div className="row gy-4">

                            <div className="col-md-6">
                                <div className="info-item  d-flex align-items-center">
                                    <i className="icon bi bi-map flex-shrink-0"></i>
                                    <div>
                                        <h3>Our Address</h3>
                                        <p>Mandir Sati Mata Sheela Devi, Thathari Gate, Batala, Punjab 143505</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="info-item d-flex align-items-center">
                                    <i className="icon bi bi-envelope flex-shrink-0"></i>
                                    <div>
                                        <h3>Email Us</h3>
                                        <p>akhilbeotramahajansabha@gmail.com</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="info-item  d-flex align-items-center">
                                    <i className="icon bi bi-telephone flex-shrink-0"></i>
                                    <div>
                                        <h3>Call Us</h3>
                                        <p>98151-19129, 75720-00006, 98155-37222, 98726-54176</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="info-item  d-flex align-items-center">
                                    <i className="icon bi bi-share flex-shrink-0"></i>
                                    <div>
                                        <h3>Opening Hours</h3>
                                        <div><strong>All Days:</strong> 7AM - 9PM
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        {/* <form action="forms/contact.php" method="post" role="form" className="php-email-form p-3 p-md-4">
                            <div className="row">
                                <div className="col-xl-6 form-group">
                                    <input type="text" name="name" className="form-control" id="name" placeholder="Your Name" required />
                                </div>
                                <div className="col-xl-6 form-group">
                                    <input type="email" className="form-control" name="email" id="email" placeholder="Your Email" required />
                                </div>
                            </div>
                            <div className="form-group">
                                <input type="text" className="form-control" name="subject" id="subject" placeholder="Subject" required />
                            </div>
                            <div className="form-group">
                                <textarea className="form-control" name="message" placeholder="Message" required></textarea>
                            </div>
                            <div className="my-3">
                                <div className="loading">Loading</div>
                                <div className="error-message"></div>
                                <div className="sent-message">Your message has been sent. Thank you!</div>
                            </div>
                            <div className="text-center"><button type="submit">Send Message</button></div>
                        </form> */}

                    </div>
                </section>

            </main>


        </Layout>
    )
}
